






import { computed, defineComponent } from '@nuxtjs/composition-api';

import { useStaffById } from '@/composables/staff-by-id';

import { BlockWorker } from './HorizontalList.types';

export default defineComponent({
  name: 'WorkersHorizontalList',
  props: {
    title: {
      type: String,
      default: 'Fler medarbetare',
    },
    currentWorkerId: {
      type: String,
      default: null,
    },
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup: (props) => {
    const { workers, workersLoading } = useStaffById({
      categoryId: parseInt(props.categoryId),
      limit: 13,
    });

    const filteredWorkers = computed(() => workers.value
      .filter(worker => worker.id !== props.currentWorkerId)
      .map(worker => ({
        firstName: worker.firstName,
        lastName: worker.lastName,
        category: worker.category,
        shouldLink: worker.shouldLink,
        to: worker.pageUrl,
        image: worker.image,
      }) as BlockWorker));

    return ({
      workers,
      workersLoading,
      filteredWorkers,
    });
  },
});
