



import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { blockColorMixin, blockableBlockColorFragment } from '@/composables/block-color';

import { ScenkonstStaffBlockDataFragment } from '@/graphql/generated';
import { PageBlockMixin } from '@/modules/cms';

@Component({
  graphqlData: gql`
    fragment ScenkonstStaffBlockData on ScenkonstStaffBlock {
      id
      heading
      staffCategoryId
      ...BlockColor
    }

    ${blockableBlockColorFragment}
  `,
})
export default class ScenkonstStaffBlock extends mixins(PageBlockMixin<ScenkonstStaffBlockDataFragment>(), blockColorMixin) { }
