
import { computed } from '@nuxtjs/composition-api';
import { useQuery } from '@vue/apollo-composable';
import { Maybe } from 'graphql/jsutils/Maybe';

import { staffByIdQuery } from '@/graphql/queries/staffByIdQuery';
import type { StaffQuery, StaffQueryVariables } from '@/graphql/generated/types';

export type StaffType = StaffQuery['scenkonstStaffByCategory']

type CategoryStaff = {
  name: string,
  workers: StaffType,
  position: Maybe<number> | null,
}

type Options = {
  categoryId: number
  limit?: number
}

export const useStaffById = (options: Options) => {
  const { result, loading } = useQuery<StaffQuery, StaffQueryVariables>(staffByIdQuery, options);

  return {
    workers: computed(() => result.value?.scenkonstStaffByCategory || []),
    workersUnderCategories: computed(() => {
      const categories: CategoryStaff[] = [];
      result.value?.scenkonstStaffByCategory.forEach((staff) => {
        if (!categories.some(category => category.name === staff.category?.name))
          categories.push({ name: staff.category!.name, workers: [staff], position: staff.category.position });
        else
          categories.find(category => category.name === staff.category?.name)?.workers?.push(staff);
      });

      categories.sort((a: CategoryStaff, b: CategoryStaff) =>
        Number(a.position === null) - Number(b.position === null) || +(a.position! > b.position!) || -(a.position! < b.position!),
      );
      return categories;
    }),
    workersLoading: loading,
  };
};
