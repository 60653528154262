import { gql } from 'graphql-tag';

import { medium1x1ImageFragment } from './image.fragments';

export const staffByIdQuery = gql`
      query staff ($categoryId: Int!, $limit: Int) {
        scenkonstStaffByCategory (categoryId: $categoryId, limit: $limit) {
          id
          firstName
          lastName
          contactEmail
          contactPhone
          pageUrl
          shouldLink
          category {
            id
            name
            position
          }
          image {
            ...Medium1x1ImageData
          }
        }
      }
      ${medium1x1ImageFragment}
    `;
